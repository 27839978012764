import api from '@/api/api'
import config from '@/config'

export async function getOilSpillResult(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors`, data.payload)
}
export async function getOilSpillLayer(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/vectors/${data.vectorId}`,
    data.payload,
  )
}
export async function getDashboard(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors/${data.vectorId}/dashboard/oil-spill`,
    data.payload,
  )
}


