<template>
  <v-layout class="fill-height overflow-hidden" column>
    <v-layout fill-height>
      <div
        style=" flex: none; height: fit-content;position: relative;"
        class="AOI-menu custom-card-bg-gradient"
        height="100%"
        min-width="340"
        width="340"
        v-if="leftMenu"
      >
        <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
          <v-icon size="26">icon-chevrons_left</v-icon>
        </v-btn>
        <v-layout class="pt-2 overflow-y-hidden" style="flex: 1; min-height: 200px;min-width: 340px;height: 100vh;">
          <v-card class="AOI-menu custom-card-bg-1 " height="100%" width="100%">
            <v-overlay :value="loading" absolute>
              <v-progress-circular indeterminate size="32"></v-progress-circular>
            </v-overlay>
            <v-layout class="pa-3 pt-5" column>
              Data Analytic
              <v-divider />
              <v-layout align-center class="fill-height">
                <v-card class="custom-card-bg-gradient" width="100%" height="100%" min-height="100">
                  <v-layout align-center column justify-center>
                    <div class="text-gradient" style="font-size: 2rem; font-weight: bold">OIL SPILL AREA</div>
                    <div style="position: relative;min-height: 50px;">
                      <div style="font-size: 24px" v-html="oilSpillArea"></div>
                    </div>
                  </v-layout>
                </v-card>
              </v-layout>
            </v-layout>
            <div class="pa-2 my-1" style="flex: none; width: 100%; height:300px; position: relative">
              Historical weather
              <div style="flex: none; width: 100%; height:100%; border-radius: 8px">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :class="hover ? 'custom-card-bg-2' : 'custom-card-bg-1'"
                    class="pa-4"
                    height="100%"
                    style="cursor: pointer"
                    width="100%"
                  >
                    <v-layout v-if="weather" class="pt-2" column fill-height>
                      <h4>{{ weather.datetime }}</h4>
                      <div>
                        <v-layout align-center>
                          <div
                            style="width: 65px; height: 65px; background-size: 100% 100%"
                            v-bind:style="{ 'background-image': `url(${getLinkImage(weather.icon)})` }"
                          ></div>
                          <span class="pl-3" style="font-size: 30px">{{ weather.temp }}°C</span>
                        </v-layout>
                      </div>
                      <div class="pt-2" style="font-size: 26px">{{ weather.tempmin }}°C/{{ weather.tempmax }}°C</div>
                      <div class="pb-1" style="font-size: 16.5px">
                        {{ weather.conditions }}
                      </div>
                      <div style="font-size: 14px">
                        <v-layout align-center>
                          Cloud:
                          <span class="pl-2"
                            >{{ weather.cloudcover }}%<v-icon class="ml-1" size="18">mdi-cloud</v-icon></span
                          >
                        </v-layout>
                      </div>
                      <div style="font-size: 14px">
                        <v-layout align-center>
                          Precipitation prob:
                          <span class="pl-2">
                            {{ weather.precipprob }}%<v-icon class="ml-1" size="18">mdi-weather-pouring</v-icon>
                          </span>
                        </v-layout>
                      </div>
                      <div style="font-size: 14px">
                        <v-layout align-center>
                          Humidity:
                          <span class="pl-2"
                            >{{ weather.humidity }}%<v-icon class="ml-1" size="18">mdi-water</v-icon></span
                          >
                        </v-layout>
                      </div>
                      <div style="font-size: 14px">
                        <v-layout align-center>
                          Wind speed:
                          <span class="pl-2"
                            >{{ weather.windspeed }} km/h
                            <v-icon
                              :style="{ transform: 'rotate(' + (90 - weather.winddir) + 'deg)' }"
                              class="ml-0"
                              size="16"
                            >
                              mdi-navigation
                            </v-icon>
                          </span>
                        </v-layout>
                      </div>
                    </v-layout>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card>
        </v-layout>
      </div>
      <div style="width: 100%; height:90%;" class="pl-2">
        <v-layout>
          <v-col ref="AOI" class="pl-0" cols="12" lg="4">
            <select2
              v-model="currentAOI"
              :items="listAOI"
              dense
              hide-details
              item-text="name"
              item-value="uuid"
              label="Select AOI"
              outlined
              placeholder="AOI"
              return-object
              @change="getService"
            />
          </v-col>
          <v-col ref="source" cols="12" lg="4">
            <v-select
              v-model="source"
              :disabled="!Object.keys(service).length"
              :items="sources"
              :loading="loading"
              dense
              hide-details
              item-text="name"
              label="Select source"
              outlined
              placeholder="Source"
              return-object
            />
          </v-col>
          <v-col ref="result" cols="12" lg="4">
            <v-select
              v-model="result"
              :disabled="!source"
              :items="data"
              :loading="loading"
              dense
              hide-details
              item-text="date"
              label="Date"
              outlined
              placeholder="Date"
              return-object
              @change="getLayers"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="0"></v-col>
        </v-layout>
        <div class="fill-height" style="position: relative;">
          <div style="position: absolute; bottom: 12px; left: 12px; z-index: 2">
            <v-btn v-if="!leftMenu" color="bgContainer" fab class="mr-2" @click="leftMenu = true">
              <v-icon>icon-menu</v-icon>
            </v-btn>
            <v-btn v-if="!layerControl" color="bgContainer" fab @click="layerControl = true">
              <v-icon>icon-layers</v-icon>
            </v-btn>
          </div>

          <Map
            ref="map"
            :current-layers.sync="layers"
            :drawType.sync="drawType"
            :isCreateText="displayType === 'text'"
            :isDraw="isCreating || isDraw"
          />
          <v-btn-toggle v-if="isCreating" style="position: absolute; top: 12px; right: 60px; z-index: 2">
            <v-btn small width="120" @click="getFlood">
              <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
              Submit
            </v-btn>
            <v-btn small width="120" @click="isCreating = false">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              close
            </v-btn>
          </v-btn-toggle>
          <TourGuide
            style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
            ref="tourGuide"
            :color="'#e3e3e3'"
            :iconColor="'#66808d'"
            v-if="elements"
            :elements="elements"
          />
        </div>
      </div>
    </v-layout>
    <MakeOrder ref="makeOrder" isOnScreenDialog />
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import Select2 from '@/components/Select2/Select2.vue'
import { mapState } from '@/store/ults'
import Map from '@/components/Map'
import { getDetailAOI } from '@/api/aoi-api'
import { getOrder } from '@/api/order'
import { getOilSpillResult, getOilSpillLayer, getDashboard } from '@/api/oil-spill-api'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import numberFormat from '@/utils/comma'
import AreaTransform from '@/utils/convertArea'
import config from '@/config'
import TourGuide from '@/components/GuideTour/index.vue'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
export default {
  components: { CardMapView, Select2, Map, LayerControl, TourGuide, MakeOrder },
  data() {
    return {
      service: {},
      layerControl: true,
      sources: [],
      source: undefined,
      currentAOI: undefined,
      loading: false,
      data: [],
      result: undefined,
      leftMenu: true,
      dataLoading: false,
      geometry: {},
      oilSpillArea: '0 km<sup>2</sup>',
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Oil Spill Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
      ],
      features: [],
      layers: [],
      drawType: '',
      displayType: '',
      isCreating: false,
      isDraw: false,
      currentImage: undefined,
      firstLoad: true,
      floodArea: undefined,
      weather: undefined,
      elements: undefined,
    }
  },
  computed: { ...mapState('AOI', ['AOIs', 'listAOI']) },
  watch: {
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  async mounted() {
    await this.getListAOI()
    this.elements = [
      {
        ref: this.$refs.AOI,
        id: 'AOI',
        title: 'Getting Started',
        text:
          this.listAOI.length > 0
            ? 'Select an AOI to get results'
            : "You don't have any AOIs with results. Please submit an order.",
        position: 'bottom',
        isHideContinueBtn: this.listAOI.length > 0 ? undefined : true,
        buttons:
          this.listAOI.length > 0
            ? undefined
            : [
                {
                  text: 'Submit Order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$refs.makeOrder.openDialog(null, this.$route.meta.service)
                  },
                },
              ],
      },
      {
        ref: this.$refs.source,
        id: 'source',
        title: 'Getting Started',
        text: 'Select a source of result',
        position: 'bottom',
      },
      {
        ref: this.$refs.result,
        id: 'result',
        title: 'Getting Started',
        text: this.data.length > 0 ? 'Select a result to show' : 'No result found, please check your order',
        position: 'bottom',
        buttons:
          this.data.length > 0
            ? undefined
            : [
                {
                  text: 'Check order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$router.push({
                      name: 'orders',
                      query: {
                        aoiId: this.currentAOI.uuid,
                        service: this.service.service_id,
                        source: this.source,
                      },
                    })
                  },
                },
              ],
      },
    ]
  },
  methods: {
    getLinkImage(icon) {
      return config.backend_pub + '/weather-icons/dark-mode/' + icon + '.png'
    },
    async getService() {
      try {
        this.loading = true
        this.resetData()
        await this.getInfoAOI()
        this.$refs.map.submitZoom(this.currentAOI.bbox)
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.currentAOI.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.service = res.data[0]
        this.sources = []
        res.data.forEach(val => this.sources.push(val.image_source))
        this.source = this.sources[0]
        if (this.source) await this.getListResult()
        else this.firstLoad = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        if (this.firstLoad) this.currentAOI = this.AOIs[0]
        if (!this.currentAOI) this.firstLoad = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.geometry = res.data.geojson.features[0].geometry
        this.$refs.map.addGeoJsonToMap(res.data.name, res.data.geojson, 'yellow', res.data.uuid, 'line', true)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    resetData() {
      this.source = undefined
      this.data = []
      this.result = undefined
    },
    async getListResult() {
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.result = undefined
        this.data = []
        const res = await getOilSpillResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.data = res.data
        this.result = this.data[0]
        // this.elements = [
        //   {
        //     ref: this.$refs.AOI,
        //     id: 'AOI',
        //     title: 'Getting Started',
        //     text:
        //       this.listAOI.length > 0
        //         ? 'Select an AOI to get results'
        //         : "You don't have any AOIs with results. Please submit an order.",
        //     position: 'bottom',
        //     buttons:
        //       this.listAOI.length > 0
        //         ? undefined
        //         : [
        //             {
        //               text: 'Submit Order',
        //               action: () => {
        //                 this.$refs.tourGuide.completeTour()
        //                 this.$refs.makeOrder.openDialog(null, this.$route.meta.service)
        //               },
        //             },
        //           ],
        //   },
        //   {
        //     ref: this.$refs.source,
        //     id: 'source',
        //     title: 'Getting Started',
        //     text: 'Select a source of result',
        //     position: 'bottom',
        //   },
        //   {
        //     ref: this.$refs.result,
        //     id: 'result',
        //     title: 'Getting Started',
        //     text: this.data.length > 0 ? 'Select a result to show' : 'No result found, please check your order',
        //     position: 'bottom',
        //     buttons:
        //       this.data.length > 0
        //         ? undefined
        //         : [
        //             {
        //               text: 'Check order',
        //               action: () => {
        //                 this.$refs.tourGuide.completeTour()
        //                 this.$router.push({
        //                   name: 'orders',
        //                   query: {
        //                     aoiId: this.currentAOI.uuid,
        //                     service: this.service.service_id,
        //                     source: this.source,
        //                   },
        //                 })
        //               },
        //             },
        //           ],
        //   },
        // ]
        if (this.result) this.getLayers()
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async getDashboard() {
      this.weather = undefined
      const res = await getDashboard({
        id: this.currentAOI.uuid,
        projectId: this.$route.params.id,
        vectorId: this.result.uuid,
      })
      this.oilSpillArea = AreaTransform.transformUnit(res.data.area)
      this.weather = res.data.weather
    },
    async getLayers() {
      try {
        this.loading = true
        this.$refs.map.removeSource('source_oil_spill')
        const res = await getOilSpillLayer({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
        })
        this.$refs.map.addVectorTiles({
          isHover: true,
          display: true,
          data: res.data.tile_info,
          tiles: [res.data.tile_info.tile_url],
          bounds: res.data.tile_info.bbox,
          paint: { 'fill-color': '#cd3c0c', 'fill-opacity': 1, 'fill-outline-color': '#f10505' },
          layerName: 'default',
          name: res.data.name,
          id: 'oil_spill',
          type: res.data.tile_info.styles.type,
        })
        this.getDashboard()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    convertUnit(area) {
      if (this.oilSpillArea.split(' ')[1] === 'km<sup>2</sup>')
        return numberFormat.numberWithCommas(Math.round((area / 1000000) * 100) / 100)
      else if (this.floodArea.split(' ')[1])
        return numberFormat.numberWithCommas(Math.round((area / 10000) * 100) / 100)
      else return numberFormat.numberWithCommas(Math.round(area * 100) / 100)
    },
  },
}
</script>

<style scoped></style>
